import { Card, Row, Typography, Tag, Space, Input } from "antd";
import { useDeviceDetailsViewModel } from "./deviceDetailsViewModel";
import { Loader } from "../../components/Loader";
import { GPS_STATE } from "../../domain/device/models";

import BatteryLevel from "../../components/BatteryLevel";
import Status from "../../components/Status";
import TagWithColor from "../../components/TagWithColor";
import { LeftOutlined } from "@ant-design/icons";
import DeviceRoomActions from "../../components/DeviceRoomActions";
import { ASSET_TYPE, PERMISSION_TYPE } from "../../domain/device/entities/auth";
import AreaPlot from "../../components/AreaPlot";
import ItemDescriptions from "../../components/ItemDescriptions";
import { POWER_SOURCE } from "../../domain/device/entities";
import { Button } from "../../components/Button";

const { Title } = Typography;
const DetailPage = () => {
  const {
		handleBack,
		data,
		loading,
		handleDeregister,
		handleRegister,
		loadingDeviceAction,
		hotelData,
		permissions,
		batteryHistory,
		wifiHistory,
		handleClearCache,
		handleReboot,
		titleClick,
		handleUpdateDescription,
		setDescription,
		description,
		guestName,
	} = useDeviceDetailsViewModel();

  const batteryLevel = data?.event?.batteryLevel || data?.report.batteryLevel;
  const wifiSsid = data?.event?.wifiSSID || data?.report.wifi_ssid;
  return loading || loadingDeviceAction ? (
		<Loader />
	) : (
		<div style={{ background: "white", padding: "20px", top: 0 }}>
			<Space>
				<LeftOutlined
					style={{ fontSize: "30px", padding: "20px", paddingLeft: "0px" }}
					onClick={handleBack}
				/>
				<Title onClick={() => titleClick()} level={2}>
					{data?.info?.name || "-"}
				</Title>
			</Space>

			<Title level={4}>Actions</Title>

			{!loadingDeviceAction &&
				data &&
				hotelData &&
				permissions?.assets
					.find((v) => v.name === ASSET_TYPE.device_dashboard)
					?.permissions.includes(PERMISSION_TYPE.Edit) && (
					<DeviceRoomActions
						handleDeregister={handleDeregister}
						handleRegister={handleRegister}
						handleReboot={handleReboot}
						handleClearCache={handleClearCache}
						data={data}
						hotelData={hotelData}
					/>
				)}

			<Title level={4}>Tablet Details</Title>
			{permissions?.assets
				.find((v) => v.name === ASSET_TYPE.device_dashboard)
				?.permissions.includes(PERMISSION_TYPE.Edit) &&
				data?.roomInfo?.roomNumber && (
					<Space>
						<Input
							maxLength={25}
							value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
						<Button ghost onClick={() => handleUpdateDescription()}>
							Update description
						</Button>
					</Space>
				)}
			<Row style={{ width: "100%" }}>
				<ItemDescriptions
					items={[
						{
							label: "Name",
							children: <>{guestName || "-"}</>,
						},
						{
							label: "Room",
							children: <Tag>{data?.roomInfo?.roomNumber}</Tag>,
						},
						{
							label: "Floor",
							children: <Tag>{data?.roomInfo?.floor}</Tag>,
						},
						{
							label: "Expected CO",
							children: (
								<>
									{data?.roomInfo?.checkOut
										? new Date(+data.roomInfo.checkOut).toLocaleDateString()
										: "Vacant"}
								</>
							),
						},
						{
							label: "Check-In Date",
							children: (
								<>
									{data?.roomInfo?.checkIn
										? new Date(+data.roomInfo.checkIn).toLocaleDateString()
										: "Vacant"}
								</>
							),
						},
						{
							label: "Guest Group Code",
							children: <Tag>{data?.roomInfo?.guestGroupCode}</Tag>,
						},
						{
							label: "Timezone",
							children: <Tag>{data?.info?.timezone_string}</Tag>,
						},
					]}
				/>

				<ItemDescriptions
					items={[
						{
							label: "State",
							children: (
								<>
									<Status value={data?.info?.state}></Status>
								</>
							),
						},
						{
							label: "Battery Level",
							children: <BatteryLevel battery_level={batteryLevel} />,
						},
						{
							label: "Power Source",
							children: (
								<Tag>
									{data?.event.powerSource &&
									[
										POWER_SOURCE.BATTERY_PLUGGED_AC,
										POWER_SOURCE.BATTERY_PLUGGED_USB,
									].includes(data?.event.powerSource)
										? "Charging"
										: data?.event.powerSource === POWER_SOURCE.BATTERY_UNPLUGGED
										? "Discharging"
										: ""}
								</Tag>
							),
						},
						{
							label: "Brightness",
							children: data?.event?.brightness && (
								<TagWithColor
									value={data?.event?.brightness}
									colorCodingFn={(v) =>
										v < 30 ? "red" : v < 60 ? "orange" : "green"
									}
								>
									{data?.event?.brightness}%
								</TagWithColor>
							),
						},
					]}
				/>
				{data?.event.sound && (
					<ItemDescriptions
						items={Object.entries(data?.event.sound).map(
							([audioStream, volumeLevel], index) => ({
								label: audioStream.replace("STREAM_", "").toLowerCase(),
								children: (
									<TagWithColor
										value={volumeLevel}
										colorCodingFn={(v) =>
											v < 30 ? "red" : v < 60 ? "orange" : "green"
										}
									>
										{volumeLevel}%
									</TagWithColor>
								),
							})
						)}
					/>
				)}

				<ItemDescriptions
					items={[
						{
							label: "Kiosk",
							children: <Tag>{data?.app?.appName}</Tag>,
						},
						{
							label: "Version",
							children: <Tag>{data?.app?.version}</Tag>,
						},
						{
							label: "GPS State",
							children: (
								<TagWithColor
									colorCodingFn={(v) =>
										v === GPS_STATE.LOCATION_MODE_OFF ? "red" : "green"
									}
									value={data?.event?.gpsState}
								>
									{data?.event?.gpsState}
								</TagWithColor>
							),
						},
						{
							label: "Bluetooth",
							children: (
								<TagWithColor
									value={data?.event?.bluetoothState}
									colorCodingFn={(v) => (v ? "green" : "red")}
								>
									{data?.event?.bluetoothState ? "Active" : "Inactive"}
								</TagWithColor>
							),
						},
					]}
				/>
				<ItemDescriptions
					items={[
						{
							label: "Network",
							children: data?.report?.currentActiveConnection,
						},
						{
							label: "Wifi MAC",
							children: data?.info?.macAddress,
						},
						{
							label: "IP Address",
							children: data?.event?.ipv4Address,
						},
						{
							label: "WIFI SSID",
							children: wifiSsid,
						},
						data?.event?.wifiSignal && data?.event?.wifiSignal !== null
							? {
									label: "Wifi Signal",
									children: (
										<TagWithColor
											colorCodingFn={(val) =>
												val < 2 ? "red" : val < 4 ? "orange" : "green"
											}
											value={data?.event?.wifiSignal}
										>
											{data?.event?.wifiSignal}
										</TagWithColor>
									),
							  }
							: undefined,
						{
							label: "WIFI Signal Dbm",
							children: <Tag>{data?.report?.signalStrengthDbm}</Tag>,
						},
						{
							label: "Upload",
							children: data?.event?.uploadSpeed,
						},
						{
							label: "Download",
							children: data?.event?.downloadSpeed,
						},
					]}
				/>

				<ItemDescriptions
					items={[
						{
							label: "Brand",
							children: data?.info?.hardwareInfo?.brand,
						},
						{
							label: "Base version",
							children: data?.info?.hardwareInfo.baseVersion,
						},
						{
							label: "Model",
							children: data?.info?.hardwareInfo.model,
						},
						{
							label: "HW Serial",
							children: data?.info?.hardwareInfo?.Serial,
						},
						{
							label: "Android build number",
							children: data?.info?.androidBuildNumber,
						},
					]}
				/>
			</Row>
			<Title level={4}>Statistics</Title>

			{batteryHistory && (
				<Card
					title={"Battery Level"}
					style={{ width: "100%", marginBottom: "50px" }}
				>
					<AreaPlot<{ batteryLevel?: number | undefined }>
						data={batteryHistory}
						dataKey="batteryLevel"
						max={100}
					/>
				</Card>
			)}
			{wifiHistory && (
				<Card title={"Wifi Signal Strength"} style={{ width: "100%" }}>
					<AreaPlot<{ wifiLevel?: number | undefined }>
						data={wifiHistory}
						dataKey="wifiLevel"
						max={6}
					/>
				</Card>
			)}
		</div>
	);
};

export default DetailPage;
