import { useRef, useState } from "react";
import { Input, Button, Form, Typography, Spin } from "antd";
import type { InputRef } from "antd";
import {
  AccountUpdateFields,
  useAuthStore,
  useManageAccountStore,
} from "../../../store";
import { preUpdateEmailUseCase } from "../../../useCases/userProfile/preUpdateEmailUseCase";

const { Text } = Typography;

const EmailResetForm = () => {
  const [isCalling, setIsCalling] = useState<boolean>(false);
  const { userProfile } = useAuthStore();
  const currentEmail =
    userProfile && userProfile.email ? userProfile.email : "";
  const newEmailRef = useRef<InputRef | null>(null);
  const {
    setIsResetFormsVisible,
    setIsOTPVisible,
    setNewValue,
    setAccountFieldToUpdate,
  } = useManageAccountStore();

  const handleSubmit = async (values: any) => {
    const { newEmail } = values;

    if (!newEmail) return;

    // pre update email call, triggers the OTP email message TO THE NEW EMAIL ADDRESS!!!!
    setIsCalling(true);
    const res = await preUpdateEmailUseCase({ newEmail });
    setIsCalling(false);

    if (!res?.success) return;

    // on success
    setAccountFieldToUpdate(AccountUpdateFields.EMAIL);
    setIsResetFormsVisible(false);
    setNewValue(newEmail);
    setIsOTPVisible(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Text style={{ fontSize: 14, fontWeight: 300, marginBottom: 20 }}>
        Reset your email address
      </Text>
      <Form
        onFinish={handleSubmit}
        initialValues={{ newEmail: currentEmail }}
        style={{ width: 300 }}
      >
        <Form.Item
          name="newEmail"
          label="Email"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            { required: true, message: "Please input your new email!" },
            { type: "email", message: "The input is not a valid email!" },
          ]}
        >
          <Input
            ref={newEmailRef}
            placeholder="Enter new email"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item>
          {isCalling ? (
            <Spin />
          ) : (
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Reset Email
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default EmailResetForm;
