import React, { memo, useEffect, useState } from "react";
import { DEVICE_STATUS, DeviceEntity } from "../../domain/device/entities";
import Pie from "../Pie";
import { Collapse } from "antd";
export enum ChartType {
  Battery,
  Wifi,
  Staus,
}

const PropertyMetrics = ({
  data,
  onFilter,
  onSort,
}: {
  data: DeviceEntity[];
  onFilter?: (data: { type: ChartType; value: any }) => void;
  onSort?: (type: ChartType) => void;
}) => {
  const [connected, setConnected] = useState(0);
  const [offline, setOffline] = useState(0);
  const [batteryLow, setBatteryLow] = useState(0);
  const [batteryMedium, setBatteryMedium] = useState(0);
  const [batteryHigh, setBatteryHigh] = useState(0);

  const [wifiLow, setWifiLow] = useState(0);
  const [wifiMedium, setWifiMedium] = useState(0);
  const [wifiHigh, setWifiHigh] = useState(0);

  const [occupied, setOccupied] = useState(0);
  const [unoccupied, setUnoccupied] = useState(0); 

  useEffect(() => {
    const statusOnline_ofline = [0, 0];
    const batteryHigh_medium_low = [0, 0, 0];
    const wifiHigh_medium_low = [0, 0, 0];
    const occupiedUnoccupied = [0, 0];

    data.forEach((device) => {
      const wifiSignal = device?.event?.wifiSignal;
      wifiSignal < 2
        ? wifiHigh_medium_low[2]++
        : wifiSignal < 4
        ? wifiHigh_medium_low[1]++
        : wifiHigh_medium_low[0]++;
      device?.event?.batteryLevel <= 50
        ? batteryHigh_medium_low[2]++
        : device?.event?.batteryLevel < 75
        ? batteryHigh_medium_low[1]++
        : batteryHigh_medium_low[0]++;
      if (device?.info.state === DEVICE_STATUS.Online) {
        statusOnline_ofline[0]++;
      } else if (device?.info.state === DEVICE_STATUS.Offline) {
        statusOnline_ofline[1]++;
      }

      // Check if the device is occupied or unoccupied
      if (device.roomInfo?.checkOut) {
        occupiedUnoccupied[0]++; // Occupied
      } else {
        occupiedUnoccupied[1]++; // Unoccupied
      }
    });

    setConnected(statusOnline_ofline[0]);
    setOffline(statusOnline_ofline[1]);
    setBatteryHigh(batteryHigh_medium_low[0]);
    setBatteryMedium(batteryHigh_medium_low[1]);
    setBatteryLow(batteryHigh_medium_low[2]);

    setWifiHigh(wifiHigh_medium_low[0]);
    setWifiMedium(wifiHigh_medium_low[1]);
    setWifiLow(wifiHigh_medium_low[2]);

    setOccupied(occupiedUnoccupied[0]);
    setUnoccupied(occupiedUnoccupied[1]);
  }, [data]);

  return (
    <Collapse
      defaultActiveKey={["1"]}
      items={[
        {
          key: "1",
          label: "Metrics",
          children: (
            <div
              style={{
                display: "flex",
                maxWidth: "100%",
                flexWrap: "wrap",
                justifyContent: "space-around",
                // overflowX: "hidden",
                // overflowY: "auto",
              }}
            >
              <Pie
                data={[
                  {
                    type: "Occupied",
                    value: occupied,
                    color: "#52C41A",
                  },
                  {
                    type: "Unoccupied",
                    value: unoccupied,
                    color: "#6f3ba3",
                  },
                ]}
                title="Occupied / Unoccupied"
                plotClicked={() => onSort && onSort(ChartType.Staus)}
                elementSelected={(data) =>
                  onFilter &&
                  onFilter({ type: ChartType.Staus, value: data.type })
                }
              />
              <Pie
                data={[
                  {
                    type: "Online",
                    value: connected,
                    color: "#52C41A",
                  },
                  {
                    type: "Offline",
                    value: offline,
                    color: "#FF363F",
                  },
                ]}
                title="Connected / Online"
                plotClicked={() => onSort && onSort(ChartType.Staus)}
                elementSelected={(data) =>
                  onFilter &&
                  onFilter({ type: ChartType.Staus, value: data.type })
                }
              />
              <Pie
                data={[
                  {
                    type: "High",
                    value: batteryHigh,
                    color: "#52C41A",
                  },
                  {
                    type: "Medium",
                    value: batteryMedium,
                    color: "#FFD80F",
                  },
                  {
                    type: "Low",
                    value: batteryLow,
                    color: "#FF363F",
                  },
                ]}
                title="Battery levels"
                plotClicked={() => onSort && onSort(ChartType.Battery)}
                elementSelected={(data) =>
                  onFilter &&
                  onFilter({ type: ChartType.Battery, value: data.type })
                }
              />
              <Pie
                data={[
                  {
                    type: "High",
                    value: wifiHigh,
                    color: "#52C41A",
                  },
                  {
                    type: "Medium",
                    value: wifiMedium,
                    color: "#FFD80F",
                  },
                  {
                    type: "Low",
                    value: wifiLow,
                    color: "#FF363F",
                  },
                ]}
                title="Wifi Signal Strength"
                plotClicked={() => onSort && onSort(ChartType.Wifi)}
                elementSelected={(data) =>
                  onFilter &&
                  onFilter({ type: ChartType.Wifi, value: data.type })
                }
              />
            </div>
          ),
        },
      ]}
    />
  );
};

export default memo(PropertyMetrics);
