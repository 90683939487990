import React, { useEffect, useState } from "react";
import { DeviceEntity } from "../../domain/device/entities/index";
import DeregisterRoomModal from "./DeregisterRoomModal";
import RegisterRoomModal from "./RegisterRoomModal";
import { Button } from "../Button";
import { Space } from "antd";
import {
  RegisterHandlerParams,
  SubmitRegisterAction,
} from "../../pages/DeviceDetails/deviceDetailsViewModel";

interface DeviceRoomActionsProps {
  data: DeviceEntity;
  handleDeregister: () => void;
  handleReboot: () => void;
  handleClearCache: () => void;
  handleRegister: (
    params: RegisterHandlerParams
  ) => Promise<SubmitRegisterAction | undefined>;
  hotelData: { name: string; id: string };
}
const DeviceRoomActions = ({
  data,
  handleDeregister,
  handleRegister,
  handleReboot,
  handleClearCache,
  hotelData,
}: DeviceRoomActionsProps) => {
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [deregisterModalOpen, setDeregisterModalOpen] = useState(false);
  return (
    <Space>
      {data?.roomInfo?.floor ? (
        <>
          <DeregisterRoomModal
            onCancel={() => setDeregisterModalOpen(false)}
            open={deregisterModalOpen}
            onSubmit={() => {
              setDeregisterModalOpen(false);
              handleDeregister();
            }}
            hotelData={hotelData!}
            roomInfo={data.roomInfo}
          />
          <Button ghost onClick={() => setDeregisterModalOpen(true)}>
            De-register from room
          </Button>
        </>
      ) : (
        <>
          {hotelData && (
            <>
              <RegisterRoomModal
                onCancel={() => setRegisterModalOpen(false)}
                open={registerModalOpen}
                onSubmit={(params) => handleRegister(params)}
                hotelData={hotelData}
                macAddress={data!.info?.macAddress}
              />
              <Button ghost onClick={() => setRegisterModalOpen(true)}>
                Register Room
              </Button>
            </>
          )}
        </>
      )}
      <Button ghost onClick={() => handleReboot()}>
        Reboot
      </Button>
      <Button ghost onClick={() => handleClearCache()}>
        Clear Cache
      </Button>
    </Space>
  );
};

export default DeviceRoomActions;
